import { addPropertyControls, ControlType } from "framer"
import TailSpin from "./TailSpin.tsx"

addPropertyControls(Button, {
    text: {
        type: ControlType.String,
        title: "Button Title",
    },
    onClick: {
        type: ControlType.EventHandler,
        title: "On Click",
    },
    showOnClick: {
        type: ControlType.ComponentInstance,
        title: "Show On Click",
    },
    dataClass: {
        type: ControlType.String,
        title: "Data Class",
    },
    id: {
        type: ControlType.String,
        title: "ID",
    },
    fontWeight: {
        type: ControlType.Enum,
        defaultValue: "Regular",
        options: ["Regular", "Medium", "SemiBold"],
    },
})

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function Button({
    text = "Start Filing Now",
    loading = false,
    dataClass = "",
    style = {},
    ...props
}) {
    const fontFamily = `"Inter Tight"`
    const cstyle = {
        fontFamily,
        fontWeight: 500,
        pointerEvents: loading ? "none" : "auto",
        opacity: loading ? 0.7 : 1,
        ...containerStyle,
        ...style,
    }
    return (
        <button data-class={dataClass} {...props} style={cstyle}>
            {loading && <TailSpin height={"1em"} />}
            {text}
        </button>
    )
}

Button.defaultProps = {
    text: "Start Filing Now",
    id: null,
    dataClass: "",
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    height: 50,
    backgroundColor: "#1b1b1b",
    padding: "14px 45px 14px",
    whiteSpace: "nowrap",
    color: "white",
    border: "none",
    fontSize: 18,
    lineHeight: "1em",
    outline: "none",
    cursor: "pointer",
}
